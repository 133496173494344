import { PortalStore } from "../../types/PortalStore";
import StoreAction from "../../types/StoreAction";
import { ActionTypes } from "../actions/ActionTypes";

import { defaultMPDetails } from "../../types/MPDetails";
import { defaultUIStatus } from "../../types/UIStatus";
import { defaultMPVotes } from "../../types/MPVoteData";
import { defaultMyVotes } from "../../types/MyVoteData";
import { defaultVotes } from "../../types/VoteData";
import { defaultLocationDetails } from "../../types/Location";
import { defaultPartyDetails } from "../../types/Party";
import { defaultGenderDetails } from "../../types/Gender";
import { defaultAgeDetails } from "../../types/Age";


const initialState: PortalStore = {
    age_details: defaultAgeDetails,
    ages: [],
    ages_loading: true,
    current_vote: 0,
    gender_details: defaultGenderDetails,
    genders: [],
    genders_loading: true,
    has_profile: false,
    has_voted: false,
    location_details: defaultLocationDetails,
    locations: [],
    locations_loading: true,
    mp_details: defaultMPDetails,
    mp_details_loading: true,
    mp_votes: defaultMPVotes,
    mp_votes_loading: true,
    my_votes: defaultMyVotes,
    my_votes_loading: true,
    mps: [],
    mps_loading: true,
    party_details: defaultPartyDetails,
    parties: [],
    parties_loading: true,
    ping: undefined,
    profile: undefined,
    profile_loading: true,
    user: undefined,
    uiStatus : defaultUIStatus,
    votes: defaultVotes,
    votes_loading: true
};


const updateObject = (oldObject: any, updatedValues: any) => {
    return {
        ...oldObject,
        ...updatedValues
    }
};


const portalReducer = (state = initialState, action: StoreAction) => {
    switch (action.type) {
        case ActionTypes.DO_PING:
            return updateObject(state, {ping: action.ping});
        case ActionTypes.GET_AGES:
            return updateObject(state, {ages: action.ages, ages_loading: action.ages_loading});    
        case ActionTypes.GET_GENDERS:
            return updateObject(state, {genders: action.genders, genders_loading: action.genders_loading});
        case ActionTypes.GET_LOCATIONS:
            return updateObject(state, {locations: action.locations, locations_loading: action.locations_loading});
        case ActionTypes.GET_MP_DETAILS:
            return updateObject(state, {mp_details: action.mp_details, mp_details_loading: action.mp_details_loading});    
        case ActionTypes.GET_MPS:
            return updateObject(state, {mps: action.mps, mps_loading: action.mps_loading});
        case ActionTypes.GET_MP_VOTES:
            return updateObject(state, {mp_votes: action.mp_votes, mp_votes_loading: action.mp_votes_loading});
        case ActionTypes.GET_MY_VOTES:
            return updateObject(state, {my_votes: action.my_votes, my_votes_loading: action.my_votes_loading});
        case ActionTypes.GET_PARTIES:
            return updateObject(state, {parties: action.parties, parties_loading: action.parties_loading});    
        case ActionTypes.GET_PROFILE:
            return updateObject(state, {profile: action.profile, profile_loading: action.profile_loading});
        case ActionTypes.HAS_VOTED:
            return updateObject(state, {has_voted: action.has_voted});
        case ActionTypes.SET_USER:
            return updateObject(state, {user: action.user});
        case ActionTypes.STORE_AGE_DETAILS:
            return updateObject(state, {age_details: action.age_details});
        case ActionTypes.STORE_GENDER_DETAILS:
            return updateObject(state, {gender_details: action.gender_details});
        case ActionTypes.STORE_LOCATION_DETAILS:
            return updateObject(state, {location_details: action.location_details});
        case ActionTypes.STORE_PARTY_DETAILS:
            return updateObject(state, {party_details: action.party_details});
        case ActionTypes.UPDATE_CURRENT_VOTE:
            return updateObject(state, {current_vote: action.current_vote});
        case ActionTypes.UPDATE_LOAD_MP_VOTE_STATUS:
            return updateObject(state, {mp_votes_loading: action.mp_votes_loading});
        case ActionTypes.UPDATE_LOAD_MY_VOTE_STATUS:
            return updateObject(state, {my_votes_loading: action.my_votes_loading});
        case ActionTypes.UPDATE_LOAD_PROFILE_STATUS:
            return updateObject(state, {profile_loading: action.profile_loading});
        case ActionTypes.UPDATE_LOAD_VOTE_STATUS:
            return updateObject(state, {votes_loading: action.votes_loading});
        case ActionTypes.UPDATE_PROFILE:
            return updateObject(state, {profile: action.profile, profile_loading: action.profile_loading});
        case ActionTypes.UPDATE_PROFILE_PRESENCE:
            return updateObject(state, {has_profile: action.has_profile});
        case ActionTypes.UPDATE_UI_STATUS:
            return updateObject(state, {uiStatus: action.uiStatus});
        case ActionTypes.UPDATE_VOTES:
            return updateObject(state, {votes: action.votes, votes_loading: action.votes_loading});
    }

    return state;
}

export default portalReducer;
