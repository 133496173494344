export const defaultMyVotes:MyVoteData[] = [
    {date: '1970-01-01', rating: 0},
    {date: '1970-01-02', rating: 0},
    {date: '1970-01-03', rating: 0},
    {date: '1970-01-04', rating: 0},
    {date: '1970-01-05', rating: 0},
    {date: '1970-01-06', rating: 0},
    {date: '1970-01-07', rating: 0}
];

export interface MyVoteData {
    date: string,
    rating: number
}