const genders = [
    {id: 0, name: "Not Set"},
    {id: 1, name: "Male"},
    {id: 2, name: "Female"},
    {id: 3, name: "Non binary"},
    {id: 4, name: "Transgender"},
    {id: 5, name: "Intersex"},
    {id: 10, name: "Other"},
    {id: 9999, name: "Prefer not to say"},
];

export function getGenders(idToken: string) {
    
    return new Promise(function (resolve, reject) {
        resolve(genders.sort((a, b) => a.name.localeCompare(b.name)));
    });
}

export function getGenderDetails(idToken: string, gender:number) {
    
    return new Promise(function (resolve, reject) {
        resolve(genders.find(element => element.id === gender));
    });
}
