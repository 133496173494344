import React from 'react';
import ReactDOM from 'react-dom';

import { AmplifyProvider } from '@aws-amplify/ui-react';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import reportWebVitals from './reportWebVitals';

import App from './App';
import PortalReducer from './store/reducers/Portal';

import './index.css';


import { setAutoFreeze } from 'immer';


setAutoFreeze(false);

const rootReducer = combineReducers({
    portal: PortalReducer
});


const composeEnhancers =
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AmplifyProvider>
        <App />
      </AmplifyProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
