
export type WarningType = "success" | "info" | "warning" | "critical" | undefined;

export interface UIStatus {
    warningBannerTitle: string;
    warningBannerStatus: WarningType;
    warningBannerEnabled: boolean;
}

export const defaultUIStatus: UIStatus = {
    warningBannerTitle: "initial ",
    warningBannerStatus: "warning",
    warningBannerEnabled: false
};


