import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { updateProfileLocation } from "../../store/actions/Profile";
import { getLocationsAction } from "../../store/actions/Locations";

import { Location } from "../../types/Location";
import Loading from '../Loading';


export default function LocationsList() {
    const dispatch = useDispatch();
    const location = useSelector((state: PortalState) => state.portal.location_details);
    const locations = useSelector((state: PortalState) => state.portal.locations);
    const loading = useSelector((state: PortalState) => state.portal.locations_loading);

    const handleLocationChange = (event: any, newValue:Location|null) => {
        if (newValue !== null) {
            dispatch(updateProfileLocation(newValue.id));
        }
    };

    const defaultProps = {
        options: locations,
        getOptionLabel: (option:Location) => tidyLocation(option)
    }

    const tidyLocation = (location:Location):string => {
        let text = `${[location.name, location.county, location.country].filter(Boolean).join(', ')}`;

        return text;
    }

    React.useEffect(() => {
        dispatch(getLocationsAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                loading ? 
                    <Loading />
                :
                    <FormControl fullWidth sx={{ m: 2 }}>
                        <Autocomplete
                            {...defaultProps}
                            id="location"
                            size="small"
                            autoHighlight
                            clearOnEscape
                            onChange={handleLocationChange}
                            defaultValue={location}
                            value={location}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select your location"
                                    placeholder="Select your location"/>
                            )}
                        />
                    </FormControl>
            }
        </>
    );
}
