import { withAuthenticator } from '@aws-amplify/ui-react';

import ProfilePage from '../pages/profilePage';


function AuthenticatedLayout() {
    return (
        <ProfilePage />
    );
}

// export default withAuthenticator(AuthenticatedLayout, {initialState: 'signIn', socialProviders: ['google']});
export default withAuthenticator(AuthenticatedLayout, {initialState: 'signIn'});
