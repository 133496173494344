const ages = [
    {id: 0, name: "Not Set", county: "", country: ""},
    {id: 1, name: "< 18"},
    {id: 2, name: "18-29"},
    {id: 3, name: "30-39"},
    {id: 4, name: "40-49"},
    {id: 5, name: "50-59"},
    {id: 6, name: "60-69"},
    {id: 7, name: "70-79"},
    {id: 8, name: "80-89"},
    {id: 9, name: "90-99"},
    {id: 10, name: "> 100"},
    {id: 9999, name: "Prefer not to say", county: "", country: ""},
];

export function getAges(idToken: string) {
    
    return new Promise(function (resolve, reject) {
        resolve(ages.sort((a, b) => a.name.localeCompare(b.name)));
    });
}

export function getAgeDetails(idToken: string, age:number) {
    
    return new Promise(function (resolve, reject) {
        resolve(ages.find(element => element.id === age));
    });
}
