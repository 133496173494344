export const defaultVotes:VoteData[] = [
    {name: 'Monday', you: 0, overall: 0},
    {name: 'Tuesday', you: 0, overall: 0},
    {name: 'Wednesday', you: 0, overall: 0},
    {name: 'Thursday', you: 0, overall: 0},
    {name: 'Friday', you: 0, overall: 0},
    {name: 'Saturday', you: 0, overall: 0},
    {name: 'Sunday', you: 0, overall: 0}
];

export interface VoteData {
    name: string,
    you: number,
    overall: number
}