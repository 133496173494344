import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Twitter from '@mui/icons-material/Twitter';


export default function BottomToolbar() {
  return (
    <Box sx={{ pb: 7 }} >
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label="Twitter" icon={<Twitter />} href="https://twitter.com/letmeratemymp" target="_blank" />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
