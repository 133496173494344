import React from 'react';

import FormControl from '@mui/material/FormControl';

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { updateProfileParty } from "../../store/actions/Profile";
import { getPartiesAction } from "../../store/actions/Parties";
import Loading from '../Loading';
import { Autocomplete, TextField } from '@mui/material';
import { Party } from '../../types/Party';


export default function PartiesList() {
    const dispatch = useDispatch();
    const party = useSelector((state: PortalState) => state.portal.party_details);
    const parties = useSelector((state: PortalState) => state.portal.parties);
    const loading = useSelector((state: PortalState) => state.portal.parties_loading);

    const handlePartyChange = (event: any, newValue:Party|null) => {
        if (newValue !== null) {
            dispatch(updateProfileParty(newValue.id));
        }
    };

    React.useEffect(() => {
        dispatch(getPartiesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const defaultProps = {
        options: parties,
        getOptionLabel: (option:Party) => option.name
    }

    return (
        <>
            {
                loading ? 
                    <Loading />
                :
                    <FormControl fullWidth sx={{ m: 2 }}>
                        <Autocomplete
                            {...defaultProps}
                            id="party"
                            size="small"
                            autoHighlight
                            clearOnEscape
                            defaultValue={party}
                            onChange={handlePartyChange}
                            value={party}
                            renderInput={(params) => (
                                <TextField {...params} label="Your Party Affiliation" placeholder="Your Party Affiliation"/>
                            )}
                        />
                    </FormControl>
            }
        </>
    );
}
