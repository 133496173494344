import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loading() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
            <Typography
                variant="caption"
                component="div"
                color="text.secondary">Loading...</Typography>
        </Box>
    );
}