import React from 'react';

import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from "recharts";

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { getVotesAction } from '../../store/actions/Votes';
import Loading from '../Loading';


export default function AgesList() {
    const dispatch = useDispatch();
    const votes = useSelector((state: PortalState) => state.portal.votes);
    const votes_loading = useSelector((state: PortalState) => state.portal.votes_loading);

    const tickFormatter = (value: number, index: number) => {
        switch (value) {
            case 10:
            case 9:
            case 8:
            case 7:
            case 6:
            case 5:
            case 4:
            case 3:
            case 2:
            case 1:
                return 'Positive';
            case 0:
                return 'Neutral';
            case -10:
            case -9:
            case -8:
            case -7:
            case -6:
            case -5:
            case -4:
            case -3:
            case -2:
            case -1:
                return 'Negative';
            default:
                return 'Unknown';
        }
    };

    React.useEffect(() => {
        dispatch(getVotesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                votes_loading ? 
                    <Loading />
                :
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={votes}>
                            <XAxis dataKey="name"/>
                            <YAxis tickFormatter={tickFormatter}/>
                            <Tooltip/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="you" stroke="#8884d8" />
                            <Line type="monotone" dataKey="overall" stroke="#82ca9d" />
                        </LineChart>
                    </ResponsiveContainer>
            }
        </>
    );
}
