interface Props {
    url_id: number;
}

export default function Headshot(props:Props) {
    const url = `https://members-api.parliament.uk/api/Members/${props.url_id}/Thumbnail`;

    return (
        <div style={{marginTop: "5px", marginLeft: "5px", maxWidth: "200px"}}>
            <img alt="headshot" src={url} style={{minWidth: "50px", maxWidth: "175px", objectFit: "contain", borderRadius: "50%"}}/>
        </div>
    );
}