enum ActionTypes {
    DO_PING = 'DO_PING',
    GET_AGES = 'GET_AGES',
    GET_GENDERS = 'GET_GENDERS',
    GET_LOCATIONS = 'GET_LOCATIONS',
    GET_MP_DETAILS = 'GET_MP_DETAILS',
    GET_MPS = 'GET_MPS',
    GET_MP_VOTES = 'GET_MP_VOTES',
    GET_MY_VOTES = 'GET_MY_VOTES',
    GET_PARTIES = 'GET_PARTIES',
    GET_PROFILE = 'GET_PROFILE',
    HAS_VOTED = 'HAS_VOTED',
    SET_USER = 'SET_USER',
    STORE_AGE_DETAILS = 'STORE_AGE_DETAILS',
    STORE_GENDER_DETAILS = 'STORE_GENDER_DETAILS',
    STORE_LOCATION_DETAILS = 'STORE_LOCATION_DETAILS',
    STORE_PARTY_DETAILS = 'STORE_PARTY_DETAILS',
    UPDATE_CURRENT_VOTE = 'UPDATE_CURRENT_VOTE',
    UPDATE_LOAD_MP_VOTE_STATUS = 'UPDATE_LOAD_MP_VOTE_STATUS',
    UPDATE_LOAD_MY_VOTE_STATUS = 'UPDATE_LOAD_MY_VOTE_STATUS',
    UPDATE_LOAD_PROFILE_STATUS = 'UPDATE_LOAD_PROFILE_STATUS',
    UPDATE_LOAD_VOTE_STATUS = 'UPDATE_LOAD_VOTE_STATUS',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
    UPDATE_PROFILE_PRESENCE = 'UPDATE_PROFILE_PRESENCE',
    UPDATE_UI_STATUS = 'UPDATE_UI_STATUS',
    UPDATE_VOTES = 'UPDATE_VOTES'
}

export {
    ActionTypes
}
