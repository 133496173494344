import { getGenders } from "../../api/GetGenders";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import { PortalState } from "../../types/PortalState";
import { Gender } from "../../types/Gender";


const storeGendersInStore: ActionCreator<Action> = (genders: Gender[]) => {
    return {
        type: ActionTypes.GET_GENDERS,
        genders: genders,
        genders_loading: false
    };
}

export const storeGenderDetailsInStore: ActionCreator<Action> = (gender: Gender) => {
    return {
        type: ActionTypes.STORE_GENDER_DETAILS,
        gender_details: gender,
    };
}

export const getGendersAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            getGenders('').then(async (data: any) => {
                dispatch(storeGendersInStore(data));
            });
        } catch (err) {
            console.error(err);
        }
    }
}
