import { Auth } from "aws-amplify";

import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import {PortalState} from "../../types/PortalState";
import { VoteData } from "../../types/VoteData";
import getMPVotes from "../../api/GetMPVotes";
import getMyVotes from "../../api/GetMyVotes";
import { defaultMyVotes, MyVoteData } from "../../types/MyVoteData";
import { defaultMPVotes, MPVoteData } from "../../types/MPVoteData";
import { PortalStore } from "../../types/PortalStore";
import castVote from "../../api/CastVote";


export const updateCurrentVoteAction: ActionCreator<Action> = (current_vote:number|number[]) => {
    return {
        type: ActionTypes.UPDATE_CURRENT_VOTE,
        current_vote: current_vote
    };
}

const markMPVoteLoadStatus: ActionCreator<Action> = (loading:boolean) => {
    return {
        type: ActionTypes.UPDATE_LOAD_MP_VOTE_STATUS,
        mp_votes_loading: loading
    };
}

const markMyVoteLoadStatus: ActionCreator<Action> = (loading:boolean) => {
    return {
        type: ActionTypes.UPDATE_LOAD_MY_VOTE_STATUS,
        my_votes_loading: loading
    };
}

const storeMPVotesInStore: ActionCreator<Action> = (votes: MPVoteData[]) => {
    return {
        type: ActionTypes.GET_MP_VOTES,
        mp_votes: votes,
        mp_votes_loading: false
    };
}

const storeMyVotesInStore: ActionCreator<Action> = (votes: MyVoteData[]) => {
    return {
        type: ActionTypes.GET_MY_VOTES,
        my_votes: votes,
        my_votes_loading: false
    };
}

const markHasCastedVote: ActionCreator<Action> = (voted:Boolean) => {
    return {
        type: ActionTypes.HAS_VOTED,
        has_voted: voted
    };
}

const getDoW = (day:number):string => {
    switch (day) {
        case 0:
            return 'Monday';
        case 1:
            return 'Tuesday';
        case 2:
            return 'Wednesday';
        case 3:
            return 'Thursday';
        case 4:
            return 'Friday';
        case 5:
            return 'Saturday';
        default:
            return 'Sunday';
    }
}

export const processVotesAction: ActionCreator<Action> = (state:PortalStore) => {
    if (!state.mp_votes_loading && !state.my_votes_loading) {
        let tmp:VoteData[] = [];

        for (var i = 0; i < state.my_votes.length; i++) {
            let vote:VoteData = {
                name: getDoW(new Date(state.my_votes[i].date).getDay()),
                overall: state.mp_votes[i].rating,
                you: state.my_votes[i].rating
            };

            tmp.push(vote);
        }

        return {
            type: ActionTypes.UPDATE_VOTES,
            votes: tmp,
            votes_loading: false
        };    
    }

    return {
        type: ActionTypes.UPDATE_LOAD_VOTE_STATUS,
        votes_loading: true
    };
}

export const getVotesAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            const currentSession = await Auth.currentSession();
            const idToken = currentSession.getIdToken().getJwtToken();

            const mp_id = getState().portal.profile?.mp;
            if (mp_id === undefined) {
                return;
            }
            
            getMPVotes(idToken).then(async (response: any) => {
                const data = response;

                if (response === undefined || response === null) {
                    dispatch(markMPVoteLoadStatus(false));
                } else {
                    dispatch(storeMPVotesInStore(data));
                    dispatch(markMPVoteLoadStatus(false));
                }

                dispatch(processVotesAction(getState().portal));
            }).catch(async (err) => {
                if (err.statusCode === 404) {
                    dispatch(storeMPVotesInStore(defaultMPVotes));
                }

                dispatch(markMPVoteLoadStatus(false));
                dispatch(processVotesAction(getState().portal));
            });

            getMyVotes(idToken, mp_id).then(async (response: any) => {
                const data = response;

                if (response === undefined || response === null) {
                    dispatch(markMyVoteLoadStatus(false));
                } else {
                    dispatch(storeMyVotesInStore(data));
                    dispatch(markMyVoteLoadStatus(false));
                }

                dispatch(processVotesAction(getState().portal));
            }).catch(async (err) => {
                if (err.statusCode === 404) {
                    dispatch(storeMyVotesInStore(defaultMyVotes));
                }

                dispatch(markMyVoteLoadStatus(false));
                dispatch(processVotesAction(getState().portal));
            });
        } catch (err) {
            console.error(err);
        }
    }
}


export const castVoteAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        const currentSession = await Auth.currentSession();
        const idToken = currentSession.getIdToken().getJwtToken();
        
        const current_vote = getState().portal.current_vote;
        const mp_id = getState().portal.profile?.mp;

        if (mp_id === undefined) {
            return;
        }

        castVote(idToken, current_vote, mp_id).then(async (response: any) => {
            console.log('Vote cast');
            dispatch(markHasCastedVote(true));
        }).catch(async (err) => {
            if (err.statusCode === 404) {
            }

            console.error(err);
        });
    }
}
