import { getMPs, getMPDetail } from "../../api/GetMPs";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import { PortalState } from "../../types/PortalState";
import { MP } from "../../types/MP";
import { MPDetails } from "../../types/MPDetails";


const storeMPsInStore: ActionCreator<Action> = (mps: MP[]) => {
    return {
        type: ActionTypes.GET_MPS,
        mps: mps,
        mps_loading: false
    };
}

const storeMPDetailsInStore: ActionCreator<Action> = (mp: MPDetails) => {
    return {
        type: ActionTypes.GET_MP_DETAILS,
        mp_details: mp,
        mp_details_loading: false
    };
}

export const getMPsAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            getMPs('').then(async (data: any) => {
                dispatch(storeMPsInStore(data));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

export const getMPDetails = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            const mp_details = getState().portal.mp_details;
            if (mp_details && mp_details.id !== 0) {
                return;
            }

            getMPDetail('', getState().portal.profile?.mp || 0).then(async (data: any) => {
                if (data) {
                    dispatch(storeMPDetailsInStore(data));
                } else {
                    console.error('MP Data not found');
                    // TODO: How to handle this? It should not actually happen.
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
}
