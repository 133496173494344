const parties = [
    {id: 0, name: "Not Set"},
    {id: 1, name: "Conservatives"},
    {id: 2, name: "Labour"},
    {id: 3, name: "Scottish National Party"},
    {id: 4, name: "Liberal Democrats"},
    {id: 5, name: "Democratic Unionist Party"},
    {id: 6, name: "Sinn Fein"},
    {id: 7, name: "Plaid Cymru"},
    {id: 8, name: "Social Democratic and Liberal Party"},
    {id: 9, name: "Alba Party"},
    {id: 10, name: "Green Party"},
    {id: 11, name: "Alliance Party"},
    {id: 12, name: "Ulster Unionist Party"},
    {id: 13, name: "Scottish Greens"},
    {id: 14, name: "Green Party Northern Ireland"},
    {id: 15, name: "Traditional Unionist Voice"},
    {id: 16, name: "People Before Profit"},
    {id: 9998, name: "No party affiliation"},
    {id: 9999, name: "Prefer not to say"},
];

export function getParties(idToken: string) {
    
    return new Promise(function (resolve, reject) {
        resolve(parties.sort((a, b) => a.name.localeCompare(b.name)));
    });
}

export function getPartyDetails(idToken: string, party:number) {
    
    return new Promise(function (resolve, reject) {
        resolve(parties.find(element => element.id === party));
    });
}
