interface HttpResponse extends Response {
  parsedBody?: {};
}

function handleResponse(response: HttpResponse) {
  if (!response.ok) {
    const message = { statusText: response.statusText, statusCode: response.status }
    throw message;
  }
  return response;
}

export default handleResponse;
