export const defaultMPDetails: MPDetails = {
    id: 0,
    name: "",
    party: "",
    position: "",
    url: "",
    ward: ""
};

export interface MPDetails {
    id: number,
    name: string,
    party: string,
    position: string,
    url: string,
    ward: string
}