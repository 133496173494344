import React from 'react';

import FormControl from '@mui/material/FormControl';

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { updateProfileGender } from "../../store/actions/Profile";
import { getGendersAction } from "../../store/actions/Genders";
import Loading from '../Loading';
import { Autocomplete, TextField } from '@mui/material';
import { Gender } from '../../types/Gender';


export default function GendersList() {
    const dispatch = useDispatch();
    const gender = useSelector((state: PortalState) => state.portal.gender_details);
    const genders = useSelector((state: PortalState) => state.portal.genders);
    const loading = useSelector((state: PortalState) => state.portal.genders_loading);

    const defaultProps = {
        options: genders,
        getOptionLabel: (option:Gender) => option.name
    }

    const handleGenderChange = (event: any, newValue:Gender|null) => {
        if (newValue !== null) {
            dispatch(updateProfileGender(newValue.id));
        }
    };

    React.useEffect(() => {
        dispatch(getGendersAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                loading ? 
                    <Loading />
                :
                    <FormControl fullWidth sx={{ m: 2 }}>
                        <Autocomplete
                            {...defaultProps}
                            id="gender"
                            size="small"
                            autoHighlight
                            clearOnEscape
                            defaultValue={gender}
                            onChange={handleGenderChange}
                            value={gender}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select your Gender"
                                    placeholder="Select your Gender"
                                    variant="outlined"/>
                            )}
                        />
                    </FormControl>
            }
        </>
    );
}
