import { getAges } from "../../api/GetAges";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import { PortalState } from "../../types/PortalState";
import { Age } from "../../types/Age";


const storeAgesInStore: ActionCreator<Action> = (ages: Age[]) => {
    return {
        type: ActionTypes.GET_AGES,
        ages: ages,
        ages_loading: false
    };
}

export const storeAgeDetailsInStore: ActionCreator<Action> = (age: Age) => {
    return {
        type: ActionTypes.STORE_AGE_DETAILS,
        age_details: age,
    };
}

export const getAgesAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            getAges('').then(async (data: any) => {
                dispatch(storeAgesInStore(data));
            });
        } catch (err) {
            console.error(err);
        }
    }
}
