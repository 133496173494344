import {CognitoUserInterface} from "@aws-amplify/ui-components";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator} from "redux";

export const setUser: ActionCreator<Action> = (user: CognitoUserInterface | undefined) => {
    console.log('SETTING_USER');
    console.log(user);
    
    return {
        type: ActionTypes.SET_USER,
        user: user
    };
}
