import api from "../config/api";
import handleResponse from "./ResponseHandler";


function getMPVotes(idToken: string) {
    return new Promise(function (resolve, reject) {
        const options = {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
        };
        
        fetch(api.getMPVotes, options)
            .then(response => handleResponse(response))
            .then(response => response.json())
            .then(data => {
                resolve(data);
            })
            .catch((response) => reject({ status: response.statusText, statusCode: response.statusCode }));
    });
}

export default getMPVotes;
