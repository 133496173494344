import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { PortalState } from '../../types/PortalState';


const getVoteText = (value: number) => {
    switch (value) {
        case 10:
        case 9:
        case 8:
        case 7:
        case 6:
        case 5:
        case 4:
        case 3:
        case 2:
        case 1:
            return 'positively';
        case 0:
            return 'neutrally';
        case -10:
        case -9:
        case -8:
        case -7:
        case -6:
        case -5:
        case -4:
        case -3:
        case -2:
        case -1:
            return 'negatively';
        default:
            return 'Unknown';
    }
};

export default function TodaysVote() {    
    const has_voted = useSelector((state: PortalState) => state.portal.has_voted);
    const current_vote = useSelector((state: PortalState) => state.portal.current_vote);

    return (
        <div>
        {
            has_voted ?
                <Typography textAlign="center">
                    You have voted {getVoteText(current_vote)} today.
                </Typography>
            :
                <></>
        }
        </div>
    );
}