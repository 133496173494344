import Grid from '@mui/material/Grid';

import Details from "../Details";
import Headshot from "../Headshot";


export default function DummyMP() {
    return (
        <Grid id="dummyMP" container direction="column" style={{marginTop: "10px", backgroundColor: "#F5F5F5", borderRadius: "10px", border: "solid grey 1px"}}>
            <Grid item xs={1} />
            <Grid item container direction="row" xs={10} style={{display: "flex", gap: "1rem" }}>
                <Headshot url_id={1423} />
                <Details name={"Boris Johnson"} position={"Prime Minister"} party={"conservatives"} />
            </Grid>
            <Grid item xs={1} />
        </Grid>
    );
}