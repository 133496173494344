import React from 'react';

import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from "react-redux";

import Details from "../Details";
import Headshot from "../Headshot";
import { PortalState } from '../../types/PortalState';
import { getMPDetails } from '../../store/actions/MPs';
import Loading from '../Loading';


export default function MPDetails() {
    const dispatch = useDispatch();
    const mp = useSelector((state: PortalState) => state.portal.mp_details);
    const loading = useSelector((state: PortalState) => state.portal.mp_details_loading);

    React.useEffect(() => {
        dispatch(getMPDetails());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        {
            loading ?
                <Loading />
            :
                <Grid container direction="column" style={{marginTop: "10px", backgroundColor: "#F5F5F5", borderRadius: "10px", border: "solid grey 1px"}}>
                    <Grid item sm={1} xs={1} />
                    <Grid item container direction="row" sm={10} xs={10} style={{ display: "flex", gap: "1rem" }}>
                        <Headshot url_id={mp.id} />
                        <Details name={mp.name} position={mp.position} party={mp.party} />
                    </Grid>
                    <Grid item sm={1} xs={1} />
                </Grid>
        }
        </>
    );
}