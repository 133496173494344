import React from 'react';

import FormControl from '@mui/material/FormControl';

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { updateProfileAge } from "../../store/actions/Profile";
import { getAgesAction } from "../../store/actions/Ages";
import Loading from '../Loading';
import { Age } from '../../types/Age';
import { Autocomplete, TextField } from '@mui/material';


export default function AgesList() {
    const dispatch = useDispatch();
    const age = useSelector((state: PortalState) => state.portal.age_details);
    const ages = useSelector((state: PortalState) => state.portal.ages);
    const loading = useSelector((state: PortalState) => state.portal.ages_loading);

    const defaultProps = {
        options: ages,
        getOptionLabel: (option:Age) => option.name
    }

    const handleAgeChange = (event: any, newValue:Age|null) => {
        if (newValue !== null) {
            dispatch(updateProfileAge(newValue.id));
        }
    };

    React.useEffect(() => {
        dispatch(getAgesAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                loading ? 
                    <Loading />
                :
                    <FormControl fullWidth sx={{ m: 2 }}>
                        <Autocomplete
                            {...defaultProps}
                            id="age"
                            size="small"
                            autoHighlight
                            clearOnEscape
                            defaultValue={age}
                            onChange={handleAgeChange}
                            value={age}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Your age range"
                                    placeholder="Your age range"
                                    variant="outlined"/>
                            )}
                        />
                    </FormControl>
            }
        </>
    );
}
