import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { saveProfileAction } from "../../store/actions/Profile";

import { PortalState } from '../../types/PortalState';
import AgesList from "../AgesList";
import GendersList from "../GendersList";
import Loading from "../Loading";
import LocationsList from "../LocationsList";
import MPList from "../MPList";
import PartiesList from "../PartiesList";


export default function UpdateProfile() {
    const dispatch = useDispatch();
    const mp = useSelector((state: PortalState) => state.portal.profile?.mp);
    const [formHasError, setFormHasError] = useState(false);

    const isFormValid = () => {
        const isValid = (mp !== 0);

        setFormHasError(!isValid);

        return isValid;
    };

    const handleSubmit = () => {
        if (isFormValid()) {
            dispatch(saveProfileAction());
        } else {
            console.log('Form not ready');
        }
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <Typography mt={2}>You don't currently have a profile set, please fill yours out now.</Typography>
                <Typography mt={2}>It is important to note that the only question you must select an answer for is the choice of MP.</Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            {
                formHasError ?
                    <Alert severity="error">Please fix the form errors before submitting.</Alert>
                :
                    <Loading />
            }

            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <MPList />

                    <PartiesList />

                    <LocationsList />

                    <GendersList />

                    <AgesList />

                    <Button variant="contained" size="large" color="primary" onClick={handleSubmit}>Save Profile</Button>
                </form>
            </Grid>
        </Grid>
    );
}