import handleResponse from "./ResponseHandler";


export default function joinWaitlist(name:string, email:string) {
    return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);

        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json'
            },
            body: formData
        };
        
        fetch('https://script.google.com/macros/s/AKfycbwpT-d9wTGto6fcRLFKmRAA2AKwh8UK2_1XNpMUWL5scZHCIMkwbz0o87RHbIlNRw/exec', options)
            .then(response => handleResponse(response))
            .then(response => response.json())
            .then(data => {
                resolve(data);
            })
            .catch((response) => reject({ status: response.statusText, statusCode: response.statusCode }));
    });
}
