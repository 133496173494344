import Typography from '@mui/material/Typography';

interface Props {
    name: string;
    position: string;
    party: string;
}


export default function Details(data:Props) {
    return (
        <div style={{marginTop: "30px", marginLeft: "10px"}}>
            <Typography variant="h4" component="h2">{data.name}</Typography>
            <Typography variant="h6" component="h2">{data.position}</Typography>
            <img alt="party" width="200vh" src={process.env.PUBLIC_URL + '/logos/' + data.party + '.svg'}/>
        </div>
    );
}