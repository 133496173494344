import { ChangeEvent, useState } from 'react';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import { joinWaitlistAction } from '../store/actions/WaitList';
import { useDispatch } from "react-redux";

export default function FrontPage() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [formHasError, setFormHasError] = useState(false);
    const [showWaitlistButton, setShowWaitlistButton] = useState(true)

    const isFormValid = () => {
        const isValid = (name !== "" && email !== "");

        setFormHasError(!isValid);

        return isValid;
    };

    const handleSubmit = () => {
        if (isFormValid()) {
            dispatch(joinWaitlistAction(name, email));
            setShowWaitlistButton(false);
            handleClose();
        } else {
            console.log('Form not ready');
        }
    };

    const handleEmailChange = (event:ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleNameChange = (event:ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    return (
        <div>
            <Typography variant="h3" align="center">Rate My MP</Typography>
            <Typography mt={2} align="left">Have you ever wanted to send a signal to your MP as to how you think they are performing? Then, this is your opportunity!</Typography>
            <Typography mt={2} align="left">Rate My MP is a socio-political experiement which aims to guage the mood of the public around politics whilst trying to keep emotion and virtiol out of the discussion as far as possible.</Typography>
            <Typography mt={5} variant="h4" align="center">How Does It Work?</Typography>
            <Typography mt={2} align="left">Very simple. Firstly sign up for an account and select your local MP. Then each day, log into your account and rate your MP on a scale of 1-10. We will do the rest: aggregating scores across all of the constituents. We will then track this score going forwards, and provide leaderboards and other interesting analysis as we expand the service. You can see an example of this <Link to="/example">here</Link></Typography>
            <Typography mt={5} variant="h4" align="center">Sounds Good, How Do I Join In?</Typography>
            <Typography mt={2} align="left">We're nearly ready with our initial prototype, so check back soon to see if we've opened registrations, but in the meantime you could also take a look at our related <a href="https://honesty.purplemonkeys.co.uk" target="_blank" rel="noreferrer">Honest Politicians</a> concept.</Typography>
            <Typography mt={2} align="left">You can also join our our waiting list so that you are notified when we officially go live.</Typography>

            {
                showWaitlistButton ?
                    <Button onClick={handleOpen} variant="contained" fullWidth style={{marginTop: "15px"}}>Join Waitlist</Button>
                :
                    <Typography mt={2} align="center" color="success.main" variant="h6">Thanks for signing up for our waiting, it won't be long before you have access.</Typography>
            }

            <Modal
                id="waitlist-modal"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}>
                    <Typography id="modal-title" variant="h6" component="h2">Join our waiting list</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>Fill out this form to join the waiting list and we'll get back to you as soon as we are ready to launch properly.</Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="name" error={formHasError}>First name</InputLabel>
                            <Input id="name" aria-describedby="name-helper-text" error={formHasError} onChange={handleNameChange}/>
                            <FormHelperText id="name-helper-text">How do you want us to address you?</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel htmlFor="email" error={formHasError}>Email address</InputLabel>
                            <Input id="email" aria-describedby="email-helper-text" error={formHasError} onChange={handleEmailChange}/>
                            <FormHelperText id="email-helper-text">We'll never share your email.</FormHelperText>
                        </FormControl>

                        <Button fullWidth variant="contained" size="large" color="primary" onClick={handleSubmit} style={{marginTop: "15px"}}>Join</Button>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}