export const defaultLocationDetails:Location = {
    id: 0,
    name: "Not Set",
    county: "",
    country: ""
}

export interface Location {
    id: number,
    name: string,
    county: string,
    country: string
}