import React, { useCallback, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from "react-router-dom";

import Amplify from "@aws-amplify/core";
import { Auth, Hub } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import '@aws-amplify/ui-react/styles.css';

import { useDispatch, useSelector } from "react-redux";

import Container from '@mui/material/Container';

import config from './aws-exports';
import AppToolbar from './components/Toolbar';
import BottomToolbar from './components/BottomToolbar';
import AuthenticatedLayout from './layouts/authenticatedLayout';
import ExamplePage from "./pages/examplePage";
import FrontPage from "./pages/frontPage";
import {setUser} from "./store/actions/Auth";

import './App.css';

Amplify.configure(config);


function App() {
    const user = useSelector((state: any) => state.portal.user);
    const dispatch = useDispatch();
    const [authState, setAuthState] = useState<AuthState>();

    const AUTH_STATE_LOCAL_STATE_KEY = 'local-authstate';

    const checkUser = async () => {
        console.log('Reloading user');

        try {
            let newUser = await Auth.currentAuthenticatedUser();

            if (newUser !== user) {
                dispatch(setUser(newUser as CognitoUserInterface));
            }
        
            if (newUser && authState === undefined) {
                const localAuthState = localStorage.getItem(AUTH_STATE_LOCAL_STATE_KEY) as AuthState;

                if (localAuthState !== authState) {
                    setAuthState(localAuthState);
                }
            }
        } catch {
            dispatch(setUser(undefined));
        }
    }

    React.useEffect(() => {
        onAuthUIStateChange((nextAuthState, authData) => {
            console.log(nextAuthState, authData);

            setAuthState(nextAuthState);
          
            const newUser = authData as CognitoUserInterface;
            if (newUser !== user) {
                dispatch(setUser(newUser as CognitoUserInterface));
                localStorage.setItem(AUTH_STATE_LOCAL_STATE_KEY, nextAuthState);
            }
        });
        
        checkUser().catch(result => {
            console.log('Updating');
            console.debug(result);
            dispatch(setUser(undefined));
        });
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);


    const hubCallback = useCallback(
        ({ payload: { event, data } }) => {
        //   console.debug('hub event detected', event, data);

          switch (event) {
              case 'signIn':
              case 'signOut':
                  checkUser();
                  break;
          }
        },
        []); //eslint-disable-line

        React.useEffect(() => {
            Hub.listen('auth', hubCallback)
            return () => {
              Hub.remove('auth', hubCallback)
            }
          }, [hubCallback])

    return (
        <BrowserRouter>
            <AppToolbar user={user} />
            <Container maxWidth="lg">
                <Routes>
                    <Route path="/" element={<FrontPage />} />
                    <Route path="/example" element={<ExamplePage />} />
                    <Route path="/user" element={<AuthenticatedLayout user={user} />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Container>
            <BottomToolbar />
        </BrowserRouter>
    );
}

export default App;
