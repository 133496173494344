import { getParties } from "../../api/GetParties";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import { PortalState } from "../../types/PortalState";
import { Party } from "../../types/Party";


const storePartiesInStore: ActionCreator<Action> = (parties: Party[]) => {
    return {
        type: ActionTypes.GET_PARTIES,
        parties: parties,
        parties_loading: false
    };
}

export const storePartyDetailsInStore: ActionCreator<Action> = (party: Party) => {
    return {
        type: ActionTypes.STORE_PARTY_DETAILS,
        party_details: party,
    };
}

export const getPartiesAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            getParties('').then(async (data: any) => {
                dispatch(storePartiesInStore(data));
            });
        } catch (err) {
            console.error(err);
        }
    }
}
