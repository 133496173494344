import {Action, Dispatch} from "redux";
import {PortalState} from "../../types/PortalState";
import joinWaitlist from "../../api/Waitlist";


export const joinWaitlistAction = (name:string|null, email:string|null) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        if ((name !== null) && (email !== null)) {
            joinWaitlist(name, email).then(async (response: any) => {
                console.log('Waitlist joined...');
            }).catch(async (err) => {
                if (err.statusCode === 404) {
                }

                console.error(err);
            });
        }
    }
}
