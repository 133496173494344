import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { Auth } from 'aws-amplify';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';

import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { useSelector } from 'react-redux';

interface IProps {
    user: CognitoUserInterface
}

export default function AppToolbar({ user }: IProps) {
    const x_user = useSelector((state: any) => state.portal.user);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const doLogout = () => {
        Auth.signOut();

        navigate("/");
    }

    const getCleanName = (name:string):string => {
        if (name) {
            const splitName = name.split(" ");

            if (splitName.length === 1) {
                return splitName[0][0];
            } else if (splitName.length === 2) {
                return splitName[0][0] + splitName[1][0]
            } else if (splitName.length === 3) {
                return splitName[0][0] + splitName[2][0]
            }
        }

        return "Unk";
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Rate My MP
                    </Typography>
                    {x_user ?
                    <>
                        <Avatar alt={user.attributes.name} onClick={handleClick} color="inherit">{getCleanName(user.attributes.name)}</Avatar>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                                },
                                '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                },
                            },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem component={Link} to="/user">
                                <Avatar /> My Page
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={doLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                    :
                        <Button component={Link} color="inherit" to="/user">Sign In</Button>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
}