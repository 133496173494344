import { getLocations } from "../../api/GetLocations";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import { PortalState } from "../../types/PortalState";
import { Location } from "../../types/Location";


const storeLocationsInStore: ActionCreator<Action> = (locations: Location[]) => {
    return {
        type: ActionTypes.GET_LOCATIONS,
        locations: locations,
        locations_loading: false
    };
}

export const storeLocationDetailsInStore: ActionCreator<Action> = (location: Location) => {
    return {
        type: ActionTypes.STORE_LOCATION_DETAILS,
        location_details: location,
    };
}

export const getLocationsAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            getLocations('').then(async (data: any) => {
                dispatch(storeLocationsInStore(data));
            });
        } catch (err) {
            console.error(err);
        }
    }
}
