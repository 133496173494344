import { Auth } from "aws-amplify";

import getProfile from "../../api/GetProfile";
import setProfile from "../../api/SetProfile";
import {ActionTypes} from "./ActionTypes";
import {Action, ActionCreator, Dispatch} from "redux";
import {PortalState} from "../../types/PortalState";
import {Profile} from "../../types/Profile";
import { getLocationDetails } from "../../api/GetLocations";
import { storeLocationDetailsInStore } from "./Locations";
import { getPartyDetails } from "../../api/GetParties";
import { storePartyDetailsInStore } from "./Parties";
import { getGenderDetails } from "../../api/GetGenders";
import { storeGenderDetailsInStore } from "./Genders";
import { getAgeDetails } from "../../api/GetAges";
import { storeAgeDetailsInStore } from "./Ages";


const storeProfileInStore: ActionCreator<Action> = (profile: Profile) => {
    return {
        type: ActionTypes.GET_PROFILE,
        profile: profile,
        profile_loading: false
    };
}

const ProfileExpired = (date:Date|undefined) => {
    if (date === undefined) {
        return true;
    }

    // minutes * seconds * milliseconds
    const hour = 60 * 60 * 1000;
    const hourago = Date.now() - hour;

    return date.getTime() > hourago;
}

const createDefaultProfile = ():Profile => {
    return {age: 0, gender: 0, location: 0, mp: 0, party: 0, Timestamp: new Date()};
}

export const getProfileAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            if (getState().portal.has_profile || !ProfileExpired(getState().portal.profile?.Timestamp)) {
                console.log('Aborting');
                dispatch(markProfileLoadStatus(false));
                return;
            }

            const currentSession = await Auth.currentSession();
            const idToken = currentSession.getIdToken().getJwtToken();
            
            const profile = getState().portal.profile;

            if (profile) {
                dispatch(storeProfileInStore(profile));
                dispatch(markProfileLoadStatus(false));
                return;
            }

            getProfile(idToken).then(async (response: any) => {
                let profile = response;

                console.log(response);

                if (response === undefined || response === null) {
                    dispatch(markProfileLoadStatus(false));
                    dispatch(markProfilePresence(false));
                } else {
                    dispatch(storeProfileInStore(profile));
                    dispatch(markProfileLoadStatus(false));
                    dispatch(markProfilePresence(true));
                }
            }).catch(async (err) => {
                dispatch(storeProfileInStore(createDefaultProfile()));
                dispatch(markProfileLoadStatus(false));
                dispatch(markProfilePresence(false));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

const markProfileLoadStatus: ActionCreator<Action> = (loading:boolean) => {
    return {
        type: ActionTypes.UPDATE_LOAD_PROFILE_STATUS,
        profile_loading: loading
    };
}

const markProfilePresence: ActionCreator<Action> = (present:boolean) => {
    return {
        type: ActionTypes.UPDATE_PROFILE_PRESENCE,
        has_profile: present
    };
}

export const saveProfileAction = () => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {
            const currentSession = await Auth.currentSession();
            const idToken = currentSession.getIdToken().getJwtToken();
            
            const profile = getState().portal.profile;
            if (profile === undefined || profile === null) {
                console.error('Profile object is not set');
                return;
            }
            dispatch(markProfileLoadStatus(true));

            setProfile(idToken, profile).then(async (response: any) => {
                console.info('Profile save successful');
                dispatch(markProfileLoadStatus(false));
                dispatch(markProfilePresence(true));
            }).catch(async (err) => {
                dispatch(markProfileLoadStatus(false));
                dispatch(storeProfileInStore(createDefaultProfile()));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

export const updateProfileAge = (age:number) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {            
            let profile:Profile|undefined = getState().portal.profile;

            if (profile === undefined || profile === null) {
                profile = createDefaultProfile();
            }
            
            profile.age = age;
            dispatch(storeProfileInStore(profile));

            getAgeDetails('', age).then(async (response: any) => {
                dispatch(storeAgeDetailsInStore(response));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

export const updateProfileGender = (gender:number) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {            
            let profile:Profile|undefined = getState().portal.profile;

            if (profile === undefined || profile === null) {
                profile = createDefaultProfile();
            }
            
            profile.gender = gender;
            dispatch(storeProfileInStore(profile));

            getGenderDetails('', gender).then(async (response: any) => {
                dispatch(storeGenderDetailsInStore(response));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

export const updateProfileLocation = (location:number) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {            
            let profile:Profile|undefined = getState().portal.profile;

            if (profile === undefined || profile === null) {
                profile = createDefaultProfile();
            }
            
            profile.location = location;
            dispatch(storeProfileInStore(profile));

            getLocationDetails('', location).then(async (response: any) => {
                dispatch(storeLocationDetailsInStore(response));
            });
        } catch (err) {
            console.error(err);
        }
    }
}

export const updateProfileMP = (mp:number) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {            
            let profile:Profile|undefined = getState().portal.profile;

            if (profile === undefined || profile === null) {
                profile = createDefaultProfile();
            }
            
            profile.mp = mp;
            dispatch(storeProfileInStore(profile));
        } catch (err) {
            console.error(err);
        }
    }
}

export const updateProfileParty = (party:number) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => PortalState) => {
        try {            
            let profile:Profile|undefined = getState().portal.profile;

            if (profile === undefined || profile === null) {
                profile = createDefaultProfile();
            }
            
            profile.party = party;
            dispatch(storeProfileInStore(profile));

            getPartyDetails('', party).then(async (response: any) => {
                dispatch(storePartyDetailsInStore(response));
            });
        } catch (err) {
            console.error(err);
        }
    }
}
