const url = "https://o6aq4xxi60.execute-api.eu-west-2.amazonaws.com/v1";

const Routes = {
    base: url,
    castVote: url + "/profile/vote",
    getMPVotes: url + "/mp/votes",
    getMyVotes: url + "/profile/votes",
    getPing: url + "/ping",
    getProfile: url + "/profile"
};

export default Routes;
