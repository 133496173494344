import api from "../config/api";
import handleResponse from "./ResponseHandler";

import { Profile } from '../types/Profile';


function setProfile(idToken: string, data: Profile|undefined) {
    return new Promise(function (resolve, reject) {
        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        
        fetch(api.getProfile, options)
            .then(response => handleResponse(response))
            .then(response => response.json())
            .then(data => {
                resolve(data);
            })
            .catch((response) => reject({ status: response.statusText, statusCode: response.statusCode }));
    });
}

export default setProfile;
