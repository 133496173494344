import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import DummyMP from "../components/DummyMP";


export default function MainPage() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const data = [
        {
          name: 'Wednesday',
          you: -10,
          overall: 10,
        },
        {
          name: 'Thursday',
          you: -5,
          overall: 5,
        },
        {
          name: 'Friday',
          you: 0,
          overall: 0,
        },
        {
          name: 'Saturday',
          you: 5,
          overall: -5,
        },
        {
          name: 'Sunday',
          you: 10,
          overall: -10,
        },
        {
          name: 'Monday',
          you: 8,
          overall: -8,
        },
        {
          name: 'Tuesday',
          you: 4,
          overall: -1,
        },
    ];

    const tickFormatter = (value: number, index: number) => {
        switch (value) {
            case 10:
            case 9:
            case 8:
            case 7:
            case 6:
            case 5:
            case 4:
            case 3:
            case 2:
            case 1:
                return 'Positive';
            case 0:
                return 'Neutral';
            case -10:
            case -9:
            case -8:
            case -7:
            case -6:
            case -5:
            case -4:
            case -3:
            case -2:
            case -1:
                return 'Negative';
            default:
                return 'Unknown';
        }
    };

    function valuetext(value: number) {
        switch (value) {
            case 10:
            case 9:
            case 8:
            case 7:
            case 6:
            case 5:
            case 4:
            case 3:
            case 2:
            case 1:
            return 'Positive';
            case 0:
            return 'Neutral';
            case -10:
            case -9:
            case -8:
            case -7:
            case -6:
            case -5:
            case -4:
            case -3:
            case -2:
            case -1:
            return 'Negative';
            default:
            return 'Unknown';
        }
    }
      
    const marks = [
      {
        value: -10,
        label: 'Negative',
      },
      {
        value: 0,
        label: 'Neutral',
      },
      {
        value: 10,
        label: 'Positive',
      },
    ];

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Typography>
                        The first box shows some details for your member of parliament.
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                </Grid>
                <Grid item xs={8}>
                    <DummyMP />
                </Grid>
                <Grid item xs={2}>
                </Grid>

                <Grid item xs={12} >
                    <Typography>
                        The next control is the vote button. Use this to cast your vote for today. Whilst you only have a single vote in a day you may change your mind at any point.
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={handleOpen} variant="outlined" fullWidth>Cast your vote</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: 400,
                              bgcolor: 'background.paper',
                              border: '2px solid #000',
                              boxShadow: 24,
                              p: 4,
                            }}>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Cast your vote
                          </Typography>
                          <Slider
                              aria-label="Temperature"
                              defaultValue={0}
                              getAriaValueText={valuetext}
                              valueLabelDisplay="auto"
                              step={1}
                              marks={marks}
                              min={-10}
                              max={10}
                            />
                        </Box>
                    </Modal>
                </Grid>
                <Grid item xs={4}>
                </Grid>

                <Grid item xs={12} >
                    <Typography>
                        The last part of the page shows the tallied votes for your member of parliament for the past 7 days. Your votes are displayed in blue, whilst the overall votes are shown in green.
                    </Typography>
                </Grid>

                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart width={750} height={300} data={data}>
                      <XAxis dataKey="name"/>
                      <YAxis tickFormatter={tickFormatter}/>
                      <Tooltip/>
                      <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                      <Line type="monotone" dataKey="you" stroke="#8884d8" />
                      <Line type="monotone" dataKey="overall" stroke="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>
        </div>
    );
}