import api from "../config/api";
import handleResponse from "./ResponseHandler";


function castVote(idToken:string, vote:number, mp_id:number) {
    const data = {
        rating: vote,
        mp: mp_id
    };
    
    return new Promise(function (resolve, reject) {
        const options = {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Authorization': idToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        
        fetch(api.castVote, options)
            .then(response => handleResponse(response))
            .then(response => response.json())
            .then(data => {
                resolve(data);
            })
            .catch((response) => reject({ status: response.statusText, statusCode: response.statusCode }));
    });
}

export default castVote;