import React, { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { PortalState } from '../../types/PortalState';
import { useDispatch, useSelector } from "react-redux";

import { updateProfileMP } from "../../store/actions/Profile";
import { getMPsAction } from "../../store/actions/MPs";
import { MP } from '../../types/MP';
import Loading from '../Loading';


export default function MPList() {
    const dispatch = useDispatch();
    const mp = useSelector((state: PortalState) => state.portal.mp_details);
    const mps = useSelector((state: PortalState) => state.portal.mps);
    const loading = useSelector((state: PortalState) => state.portal.mps_loading);
    const [formHasError, setFormHasError] = useState(true);

    const defaultProps = {
        options: mps,
        getOptionLabel: (option:MP) => tidyMPInformation(option)
    }

    const handleMPChange = (event: any, newValue:MP|null) => {
        if (newValue !== null) {
            dispatch(updateProfileMP(newValue.id));
            setFormHasError(false);
        }
    };

    const tidyMPInformation = (mp:MP):string => {
        const text = `${[mp.name, mp.party, mp.ward].filter(Boolean).join(', ')}`;

        return text;
    }

    React.useEffect(() => {
        dispatch(getMPsAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {
                loading ? 
                    <Loading />
                :
                    <FormControl fullWidth sx={{ m: 2 }} error={formHasError}>
                        <Autocomplete
                            {...defaultProps}
                            id="mp"
                            size="small"
                            autoHighlight
                            clearOnEscape
                            onChange={handleMPChange}
                            value={mp}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select your MP"
                                    placeholder="Select your MP"
                                    variant="outlined"
                                    error={!!formHasError}/>
                            )}
                        />
                    </FormControl>
            }
        </>
    );
}
