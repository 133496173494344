import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from "react-redux";

import Loading from "../components/Loading";
import UpdateProfile from "../components/UpdateProfile";
import VotingGraph from "../components/VotingGraph";
import { getProfileAction } from "../store/actions/Profile";
import { PortalState } from '../types/PortalState';
import MPDetails from '../components/MPDetails';
import { castVoteAction, updateCurrentVoteAction } from '../store/actions/Votes';
import TodaysVote from '../components/TodaysVote';

export default function ProfilePage() {
    const has_profile = useSelector((state: PortalState) => state.portal.has_profile);
    const loading = useSelector((state: PortalState) => state.portal.profile_loading);
    const current_vote = useSelector((state: PortalState) => state.portal.current_vote);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const castVote = () => {
      setOpen(false);
      dispatch(castVoteAction());
    }

    const handleVoteChange = (event:Event, value:number|number[]) => {
      dispatch(updateCurrentVoteAction(value));
    }

    function valuetext(value: number) {
      switch (value) {
          case 10:
          case 9:
          case 8:
          case 7:
          case 6:
          case 5:
          case 4:
          case 3:
          case 2:
          case 1:
          return 'Positive';
          case 0:
          return 'Neutral';
          case -10:
          case -9:
          case -8:
          case -7:
          case -6:
          case -5:
          case -4:
          case -3:
          case -2:
          case -1:
          return 'Negative';
          default:
          return 'Unknown';
      }
    }

    const marks = [
      {
        value: -10,
        label: 'Negative',
      },
      {
        value: 0,
        label: 'Neutral',
      },
      {
        value: 10,
        label: 'Positive',
      },
    ];

    React.useEffect(() => {
        dispatch(getProfileAction());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
          {
            loading ?
              <Loading />
            :
              !has_profile ?
                <UpdateProfile />
              :
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={8}>
                        <MPDetails />
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>

                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={handleOpen} variant="outlined" fullWidth>Cast your vote</Button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  width: 400,
                                  bgcolor: 'background.paper',
                                  border: '2px solid #000',
                                  boxShadow: 24,
                                  p: 4,
                                }}>
                              
                              <Typography id="modal-modal-title" variant="h6" component="h2">
                                Cast your vote
                              </Typography>

                              <Slider
                                  aria-label="Temperature"
                                  defaultValue={0}
                                  getAriaValueText={valuetext}
                                  valueLabelDisplay="auto"
                                  step={1}
                                  marks={marks}
                                  min={-10}
                                  max={10}
                                  value={current_vote}
                                  onChange={handleVoteChange}
                                />
                              
                              <Button onClick={castVote} variant="contained" fullWidth>Vote</Button>
                            </Box>
                        </Modal>
                        <TodaysVote />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>

                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                      <VotingGraph />
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
              }
        </div>
    );
}